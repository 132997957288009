<template>
  <ui-component-modal
    :modalTitle="$t(`Components.Reservation.ModalAddExternalLink.Main_Title`)"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :disableSaveButton="urlEmpty || !linkTypeSelected || isUrlValid"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="addExternalLink"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <Message
        :message="
          $t(
            'Components.Reservation.ModalAddExternalLink.Message_ExternalLinkInfo'
          )
        "
      />
      <table v-if="newExternalLink" class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>
              {{
                $t(
                  `Components.Reservation.ModalAddExternalLink.Label_AddNewLinkType`
                )
              }}
            </td>
            <td>
              <select v-model="newExternalLink.Type">
                <option
                  :value="'None'"
                  :selected="newExternalLink.Type === 'None'"
                  >Please select a type</option
                >
                <option
                  :value="'VideoCall'"
                  :selected="newExternalLink.Type === 'VideoCall'"
                  >VideoCall</option
                >
                <option
                  :value="'Document'"
                  :selected="newExternalLink.Type === 'Document'"
                  >Document</option
                >
              </select>

              <!-- <input type="text" class="input" v-model="newExternalLink.Type" /> -->
            </td>
          </tr>
          <tr>
            <td>
              {{
                $t(
                  `Components.Reservation.ModalAddExternalLink.Label_AddNewLinkUrl`
                )
              }}
            </td>
            <td>
              <input
                type="text"
                class="input"
                v-model="newExternalLink.Link"
                @keyup.enter="addExternalLink"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import reservationProvider from '@/providers/reservation'
const Message = () => import('@/components/UI/Message')

export default {
  components: {
    Message,
  },

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      newExternalLink: {
        Link: 'https://',
        Type: 'None',
      },
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),

    urlEmpty() {
      let result = true
      if (this.newExternalLink && this.newExternalLink.Link.length > 0) {
        result = false
      }

      return result
    },

    linkTypeSelected() {
      let result = true
      if (this.newExternalLink.Type === 'None') {
        result = false
      }
      return result
    },

    isUrlValid() {
      let result = true
      let matcher = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/
      let parsedLink = JSON.parse(JSON.stringify(this.newExternalLink.Link))

      if (
        matcher.test(parsedLink) &&
        !parsedLink.includes('..') &&
        !parsedLink.includes(',')
      ) {
        result = false
      }

      return result
    },
  },

  created() {},

  methods: {
    ...mapMutations('reservationStore', ['setReservation']),

    addExternalLink() {
      let self = this
      self.isSaving = true

      let reservation = JSON.parse(JSON.stringify(self.reservation))

      reservationProvider.methods
        .addExternalLink(self.reservation.Id, self.newExternalLink)
        .then((response) => {
          if (response.status === 200) {
            self.isSavingSuccess = true

            reservation.ExternalLinks.push(response.data)
            self.setReservation(reservation)

            setTimeout(() => {
              this.onClickCancel()
            }, 1500)

            EventBus.$emit('showToast', {
              type: 'info',
              message:
                self.newExternalLink.Link +
                this.$t(
                  `Components.Reservation.ModalAddExternalLink.Message_LinkIsAdded`
                ),
            })
          }
        })
        .catch((error) => {
          self.isSavingError = true
        })
        .finally(() => {
          self.isSaving = false
        })
    },
  },
}
</script>
